import React from "react";
import { useCookies } from "react-cookie";
import { AppBar, Avatar, Toolbar, Typography } from "@mui/material";

import { ColorModeToggle } from "@app/components/color-mode-toggle";
import vaultLogo from "@app/images/vault-logo-white.png";

import {
  StyledLink,
  StyledNavLink,
  StyledTopLeft,
} from "./default-app-bar.styles";

export const DefaultAppBar = ({ profile }) => {
  const [cookies] = useCookies();
  const { accountId } = cookies;

  console.log({ cookies });
  return (
    <AppBar position="static">
      <Toolbar sx={{ bgcolor: `#000000` }}>
        <StyledTopLeft>
          <StyledLink to="/">
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: `flex`,
                flexGrow: 1,
                alignItems: `center`,
                gap: `8px`,
              }}
            >
              <img
                src={vaultLogo}
                alt="VAULT"
                loading="lazy"
                width={`auto`}
                height={`36px`}
              />
            </Typography>
          </StyledLink>

          <StyledNavLink to="/">
            <Typography variant="button">LIVE</Typography>
          </StyledNavLink>

          <StyledNavLink to="/orders">
            <Typography variant="button">ORDERS</Typography>
          </StyledNavLink>
          <StyledNavLink to="/historicorders">
            <Typography variant="button">HISTORIC ORDERS</Typography>
          </StyledNavLink>
        </StyledTopLeft>

        <Typography variant="inherit" padding="1rem">
          {accountId}
        </Typography>
        {profile?.name && (
          <Avatar
            src={profile.picture}
            alt={profile.name}
            title={profile.name}
          />
        )}
        <ColorModeToggle />
      </Toolbar>
    </AppBar>
  );
};
